import * as React from 'react';
import { styled } from '@mui/material/styles';
import airtableApi from '../services/airtableApi';

import {Grid, Typography, Button, TextField, InputAdornment, Slider, Tooltip, IconButton} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Refresh } from '@mui/icons-material';

const ButtonA = styled(Button)(({ activated, theme }) => ({
  position: 'relative',
  height: 50,
  borderRadius: 0,
  color: "#D18D10",
  width: '33%',
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: "#D18D10",
    color: "white",
    transform: "scale(1.05)",
    transition: "transform 200ms",
  },
  ...(   activated && {
    backgroundColor: "#D18D10",
    color: "white",
  })
}));

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default function Simulator() {
  const [placeSelected, setPlaceSelected] = React.useState(
    {
    // parleboscq: {activated: false},
    houga: {activated: true},
    // beaucaire: {activated: false},
    value: "houga"
    });
  const [simulatorTable, setSimulatorTable] = React.useState(
    {
      // parleboscq: {
      //   hectare: 0, 
      //   capitalHa: 4000, 
      //   cotisation: 0,
      //   rates: 0.025312
      // },
      houga: {
        hectare: 0, 
        capitalHa: 4000, 
        cotisation: 0,
        rates: 0.027071
      },
      // beaucaire: {
      //   hectare: 0, 
      //   capitalHa: 4000, 
      //   cotisation: 0,
      //   rates: 0.049096
      // },
    }
  );

  const refresh = () => {
    setSimulatorTable({
      // parleboscq: {
      //   hectare: 0, 
      //   capitalHa: 4000, 
      //   cotisation: 0,
      //   rates: 0.025312
      // },
      houga: {
        hectare: 0, 
        capitalHa: 4000, 
        cotisation: 0,
        rates: 0.027071
      },
      // beaucaire: {
      //   hectare: 0, 
      //   capitalHa: 4000, 
      //   cotisation: 0,
      //   rates: 0.049096
      // },
    }
  );
  }
  const [userInfo, setUserInfo] = React.useState({domainName: "", email: "", phone: ""})
  const [disabled, setDisabled] = React.useState(true)

  const handleSliderChange = (place, type, value) => {
    const newSimulator = {...simulatorTable,
      [place]: {...simulatorTable[place], [type]: parseFloat(value)}}
    setSimulatorTable({...newSimulator, [place]: {...newSimulator[place], cotisation: newSimulator[place].hectare * newSimulator[place].capitalHa * newSimulator[place].rates}});
  };

  const [total, setTotal] = React.useState({
    cotisation: 0, 
    coutHa: 0
  });
  
  const handleSelected = (place) =>{
    switch (place){
      case 'parleboscq':
        setPlaceSelected({
          // parleboscq: {activated: true},
          houga: {activated: false},
          // beaucaire: {activated: false},
          value: place
          })
        break;
      case 'houga':
        setPlaceSelected({
          // parleboscq: {activated: false},
          houga: {activated: true},
          // beaucaire: {activated: false},
          value: place
          })
        break;
      case 'beaucaire':
        setPlaceSelected({
          // parleboscq: {activated: false},
          houga: {activated: false},
          // beaucaire: {activated: true},
          value: place
          })
        break;
      default:
        break;
    }
  }

  const sendSimulation = async () =>{
    airtableApi(simulatorTable, userInfo);
  }
  React.useEffect(() =>{
    const newTotal = {
      // cotisation: (simulatorTable.parleboscq.cotisation  + simulatorTable.houga.cotisation + simulatorTable.beaucaire.cotisation ), 
      // coutHa: (simulatorTable.parleboscq.cotisation + simulatorTable.houga.cotisation  + simulatorTable.beaucaire.cotisation )
      // / (simulatorTable.parleboscq.hectare  + simulatorTable.houga.hectare + simulatorTable.beaucaire.hectare) 

      cotisation: (simulatorTable.houga.cotisation), 
      coutHa: (simulatorTable.houga.cotisation)
      / (simulatorTable.houga.hectare)
    }

    setTotal(newTotal)
  },[simulatorTable])

  React.useEffect(() => {
    if(userInfo.domainName.length > 0 && userInfo.email.length > 0 && userInfo.phone.length > 0 ){
      setDisabled(false);
    }else{
      setDisabled(true);
    }
  },[userInfo])

  const updateSimulator = (place,type,value) =>{
    const newSimulator = {...simulatorTable,
      [place]: {...simulatorTable[place], [type]: parseFloat(value)}}
    setSimulatorTable({...newSimulator, [place]: {...newSimulator[place], cotisation: newSimulator[place].hectare * newSimulator[place].capitalHa * newSimulator[place].rates}});

  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" style={{padding: "5px"}}>
        <Grid item xs={12} style={{textAlign: "center", padding: "15px"}}>
          <Typography component="h1" variant="h2" style={{fontSize: "1.8rem", fontFamily: "Arial"}}>
            Simulation assurance gel 2023
          </Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center", paddingBottom: "10px", paddingTop: "10px"}}>
          {/* <ButtonA activated={placeSelected.parleboscq.activated} onClick={() => handleSelected('parleboscq')} variant="text">Parleboscq</ButtonA> */}
          <ButtonA activated={placeSelected.houga.activated} onClick={() => handleSelected('houga')} variant="text">Le houga</ButtonA>
          {/* <ButtonA activated={placeSelected.beaucaire.activated} onClick={() => handleSelected('beaucaire')} variant="text">Beaucaire</ButtonA> */}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" style={{padding: "20px"}}>
        <Grid item xs={12} style={{textAlign: "center", padding: "10px"}}>
          <TextField 
            id="standard-number"
            label="Hectare" 
            variant="standard" 
            type="number"
            value={simulatorTable[placeSelected.value].hectare}
            onChange={(e) => updateSimulator(placeSelected.value,'hectare', e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">ha</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <PrettoSlider
            max={300}
            value={typeof simulatorTable[placeSelected.value].hectare === 'number' ? simulatorTable[placeSelected.value].hectare : 0}
            onChange={(e) => handleSliderChange(placeSelected.value,'hectare', e.target.value)}
            aria-labelledby="input-slider"
            color="secondary"
          />        
        </Grid>
        <Grid item xs={12} style={{textAlign: "center", padding: "10px"}}>
          <TextField 
            id="standard-number"
            label="Capital assuré / ha" 
            variant="standard" 
            type="number"
            value={simulatorTable[placeSelected.value].capitalHa}
            onChange={(e) => updateSimulator(placeSelected.value,'capitalHa', e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <PrettoSlider
            min={4000}
            max={12000}
            value={typeof simulatorTable[placeSelected.value].hectare === 'number' ? simulatorTable[placeSelected.value].capitalHa : 0}
            onChange={(e) => handleSliderChange(placeSelected.value,'capitalHa', e.target.value)}
            aria-labelledby="input-slider"
          />    
        </Grid>
        <Grid item xs={12} style={{textAlign: "right", marginTop: "-20px"}}>
          <Tooltip title="Refresh">
            <IconButton onClick={refresh}>
              <RefreshIcon/>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{textAlign: "left", paddingLeft: "10px", paddingRight: "10px", marginTop: "10px"}}>
          <div style={{backgroundColor: "#397d2d", textAlign: "center", padding: "10px"}}>
            <Typography component="h2" variant="h3" style={{fontSize: "1rem", color: "white"}}>
              Cotisation de <span style={{textTransform: "uppercase"}}>{placeSelected.value}</span> : 
            </Typography>
            <Typography component="h2" variant="h3" style={{fontWeight: "bold", fontSize: "1.4rem", color: "white"}}>
              {isNaN(simulatorTable[placeSelected.value].cotisation) ? 0 : simulatorTable[placeSelected.value].cotisation.toFixed(2)} €
            </Typography>
          </div>
        </Grid>
        <div style={{backgroundColor: "#397d2d", padding: "15px", width: "100%", marginLeft: "10px", marginRight: "10px", marginBottom: "15px"}}>
          <Grid item xs={12} style={{textAlign: "left", paddingLeft: "10px", paddingRight: "10px"}}>
            <div style={{textAlign: "center"}}>
              <Typography component="h5" variant="h5" style={{fontSize: "0.8rem", color: "white"}}>
                Cotisation globale : 
              </Typography>
              <Typography component="h5" variant="h5" style={{fontWeight: "bold", fontSize: "0.9rem", color: "white"}}>
                {isNaN(total.cotisation) ? 0 : total.cotisation.toFixed(2)} €
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} style={{textAlign: "left", paddingLeft: "10px", paddingRight: "10px"}}>
          <div style={{textAlign: "center"}}>
            <Typography component="h5" variant="h5" style={{fontSize: "0.8rem", color: "white"}}>
              Coût ha global : 
            </Typography>
            <Typography component="h5" variant="h5" style={{fontWeight: "bold", fontSize: "0.9rem", color: "white"}}>
            {isNaN(total.coutHa) ? 0 : total.coutHa.toFixed(2)} €
            </Typography>
          </div>
          </Grid>
        </div>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" style={{padding: "20px"}}>
        <Grid item xs={12} style={{textAlign: "center"}}>
          <TextField 
            required
            id="standard-number"
            label="Nom de la Société" 
            variant="standard" 
            type="text"
            value={userInfo.domainName}
            onChange={(e) => setUserInfo({...userInfo, domainName: e.target.value})}
            InputLabelProps={{
              shrink: true,
            }}
          />    
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" style={{padding: "20px"}}>
        <Grid item xs={6} style={{textAlign: "center"}}>
          <TextField 
            required
            id="standard-number"
            label="Email" 
            variant="standard" 
            type="email"
            value={userInfo.email}
            onChange={(e) => setUserInfo({...userInfo, email: e.target.value})}
            InputLabelProps={{
              shrink: true,
            }}
          />    
        </Grid>
        <Grid item xs={6} style={{textAlign: "center"}}>
          <TextField 
            required
            id="standard-number"
            label="Téléphone" 
            variant="standard" 
            type="phone"
            value={userInfo.phone}
            onChange={(e) => setUserInfo({...userInfo, phone: e.target.value})}
            InputLabelProps={{
              shrink: true,
            }}
          />    
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" style={{marginTop: "20px"}}>
        <Grid item xs={12} style={{textAlign: "center", padding: "10px"}}>
          <ButtonA disabled={disabled} style={disabled ? {backgroundColor: "#d18d1091", color: "white", width: "100%"} : {backgroundColor: "#D18D10", color: "white", width: "100%"} } onClick={sendSimulation} type="button" variant="text">Soumettre la demande</ButtonA> 
        </Grid>
        </Grid>
    </>
  );
}