import React from "react";
import axios from "axios";



const airtableApi = async (newData, userInfo) => {
    let data = 
    {
        "records":[
            // {
            //     "fields": {
            //         "propriete": userInfo.domainName,
            //         "station": 'parleboscq',
            //         "capital /  ha": newData.parleboscq.capitalHa,
            //         "hectare": newData.parleboscq.hectare,
            //         "cotisation": newData.parleboscq.cotisation,
            //         "email": userInfo.email,
            //         "numero": userInfo.phone
            //     }
            // },
            {  
                "fields": {
                    "propriete": userInfo.domainName,
                    "station": 'houga',
                    "capital /  ha": newData.houga.capitalHa,
                    "hectare": newData.houga.hectare,
                    "cotisation": newData.houga.cotisation,
                    "email": userInfo.email,
                    "numero": userInfo.phone
                }
            },
                // {
                //     "fields": {
                //         "propriete": userInfo.domainName,
                //         "station": 'beaucaire',
                //         "capital /  ha": newData.beaucaire.capitalHa,
                //         "hectare": newData.beaucaire.hectare,
                //         "cotisation": newData.beaucaire.cotisation,
                //         "email": userInfo.email,
                //         "numero": userInfo.phone
                //     }
                // }
            ]
        };
    
    const result = await axios.post(
        process.env.REACT_APP_BASE_URL + "simulation_apam_site", data,
        {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer keyv8jMkQz1mbdABS",
        }
        }
        )
    return result;
}


export default airtableApi;




// IF({YOUR MULTIPLE-SELECT FIELD NAME},LEN({YOUR MULTIPLE-SELECT FIELD NAME})-
// LEN(SUBSTITUTE({YOUR MULTIPLE-SELECT FIELD NAME},",",""))+1)